import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

import { useCompanyStore } from "./company";
import { useEmployeeStore } from "../stores/employees";

// GraphQL
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "../graphql/client";
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { useNow } from "@vueuse/core";

provideApolloClient(apolloClient);

const { mutate: updateProfileRequest } = useMutation(
	gql`
    mutation updateHrProfile($updateProfileId: ID!, $data: ProfileDataInput) {
      updateHrProfile(id: $updateProfileId, data: $data) {
        message
        error
      }
    }
  `,
);

export const useHrProfileStore = defineStore("profile", {
	state: () => ({
		profile: useStorage("hrprofile", {}),
		profileInitial: {}, // used to check if profile has changed. Only set on loading profile data
		profileDataHistory: [],
		profileDataHistoryIndex: -1,
		kid: undefined,
		loading: false,
		saving: false,
	}),
	getters: {
		didProfileChange() {
			return (
				JSON.stringify(this.profile) !== JSON.stringify(this.profileInitial)
			);
		},
		isProfileEmpty() {
			return Object.keys(this.profile).length === 0;
		},
		profileHistoryItems() {
			return this.profileDataHistory.length;
		},
		profileHistory() {
			if (
				this.profileDataHistory.length > 0 &&
				this.profileDataHistoryIndex >= 0
			) {
				if (this.profileDataHistory[this.profileDataHistoryIndex]) {
					return this.profileDataHistory[this.profileDataHistoryIndex];
				}
			}
			return undefined;
		},
		profileCanNavigateNext() {
			return (
				this.profileDataHistoryIndex + 1 <= this.profileDataHistory.length - 1
			);
		},
		profileCanNavigatePrevious() {
			return (
				this.profileDataHistoryIndex > -1 && this.profileDataHistory.length > 0
			);
		},
	},
	actions: {
		async getProfile(hrProfileData) {
			if (this.loading) return;

			this.resetData();

			this.loading = true;
			const emptyScaffolding = {};
			for (const fieldGroup of hrProfileData) {
				for (const field of fieldGroup.fields) {
					if (emptyScaffolding[fieldGroup.group] === undefined) {
						emptyScaffolding[fieldGroup.group] = {};
					}
					//if (emptyScaffolding[fieldGroup.group][field.name] === undefined) {
					if (!emptyScaffolding[fieldGroup.group][field.name]) {
						emptyScaffolding[fieldGroup.group][field.name] = "";
					}
				}
			}

			// Reset directly (makes UI changes look faster)
			this.profile = { ...emptyScaffolding };
			this.profileInitial = JSON.parse(JSON.stringify(this.profile)); //{ ...this.profile };
			this.kid = null;

			//console.log(emptyScaffolding);

			const employeeStore = useEmployeeStore();

			if (!employeeStore.selectedEmployee?.id) {
				this.loading = false;
				this.profile = {};
				this.kid = null;
				return { code: "ERROR", message: "noEmployeeSelected" };
			}

			try {
				// use apollo client directly
				const result = await apolloClient.query({
					query: gql`
						query encryptedProfileData($employeeId: ID) {
							companyEmployee(where: { id: $employeeId }) {
								profileData
								profileHistory
							}
						}
					`,
					variables: { employeeId: employeeStore.selectedEmployee.id },
					fetchPolicy: "no-cache",
				});
				//console.dir(result);
				if (result.data?.companyEmployee) {
					if (result.data.companyEmployee.profileData) {
						delete result.data.companyEmployee.profileData.error;
						//console.log(result.data.user.profileData);
						this.profile = {
							...emptyScaffolding,
							...result.data.companyEmployee.profileData,
						};
						this.profileInitial = JSON.parse(JSON.stringify(this.profile));
						this.kid = employeeStore.selectedEmployee.id;
						this.loading = false;

						if (result.data.companyEmployee.profileHistory) {
							this.profileDataHistory =
								result.data.companyEmployee.profileHistory;
						}

						return { code: "SUCCESS", message: "Load complete" };
					}
					this.loading = false;
					return { code: "ERROR", message: "No profile found" };
				}
				console.dir(result);
				this.loading = false;
				return { code: "ERROR", message: "Error while loading user profile" };
			} catch (e) {
				console.dir(e);
				this.loading = false;
				return { code: "ERROR", message: `${e.message} (${e.code})` };
			}
		},
		async saveChange() {
			this.saving = true;
			try {
				const result = await updateProfileRequest({
					updateProfileId: this.kid,
					data: this.profile,
				});
				//console.dir(result);
				this.saving = false;
				if (result.errors) {
					return { code: "ERROR", message: result.errors[0].message };
				}
				if (result.data.updateHrProfile.error) {
					return {
						code: "ERROR",
						message: result.data.updateHrProfile.message,
					};
				}
				return result;
			} catch (e) {
				//console.dir(e);
				this.saving = false;
				return { code: "ERROR", message: `${e.message} (${e.code})` };
			}
		},
		async historyNext() {
			/* move index up to navigate back in history */
			if (this.profileDataHistoryIndex < this.profileDataHistory.length - 1) {
				this.profileDataHistoryIndex++;
			}
		},
		async historyPrevious() {
			/* move index to latest history item on Zero, -1 to disable history */
			if (this.profileDataHistoryIndex > -1) {
				this.profileDataHistoryIndex--;
			}
		},
		resetData() {
			this.profile = {};
			this.profileInitial = {};
			this.kid = undefined;
			this.loading = false;
			this.saving = false;
			this.profileDataHistoryIndex = -1;
		},
	},
});
