import { defineStore } from "pinia";
import { useStorage, useNow } from "@vueuse/core";

// GraphQL
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "../graphql/client";
import { useMutation, useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";

// Other stores (for resetting on logout)
import { useCompanyStore } from "./company";
import { useEmployeeStore } from "./employees";
import { useHrProfileStore } from "./profile";
import { useHrSalaryStore } from "./salaries";
import { useFinanceStore } from "./finance";
import { useHrDocumentStore } from "./hrdocuments";
import { useHrSalaryControlStore } from "./salarycontrol";

provideApolloClient(apolloClient);

// Login using email and token from magic link
const accountFragment = gql`
  fragment UserFields on User {
    id
    email
    emailHash
    employee {
      id
      name
      jobDescription
      code
      role {
        id
        canAccessFinance
        canAccessTaxation
        canManageHumanResources
        canAccessSalaryWorkflow
        canAccessCompanyCodes
        canAccessCompanyContacts
      }
      company {
        id
        code
        name
        taxId
        rcsId
        vatId
        naceId
        personInChargeAccounting {
          id
          name
          email
          phone
        }
        personInChargeHumanResources {
          id
          name
          email
          phone
        }
      }
    }
    role {
      id
      canAccessKeystone
      canSignInToApp
      canManageAccounts
      canManageRoles
      canManageAccounting
      canManageTaxation
      canManageHumanResources
      canAccessAllCompanies
    }
  }
`;

const { mutate: sendRedeemTokenRequest } = useMutation(
	gql`
    mutation signInWithToken($email: String!, $token: String!) {
      redeemUserMagicAuthToken(email: $email, token: $token) {
        ... on RedeemUserMagicAuthTokenSuccess {
          item {
            id
          }
        }
        ... on RedeemUserMagicAuthTokenFailure {
          code
          message
        }
      }
    }
  `,
	{
		fetchPolicy: "no-cache",
	}
);

// Login using username and password
const { mutate: sendLoginUserPassRequest } = useMutation(
	gql`
    mutation signInWithLogin($email: String!, $password: String!) {
      authenticateUserWithPassword(email: $email, password: $password) {
        ... on UserAuthenticationWithPasswordSuccess {
          sessionToken
          item {
            id
          }
        }
        ... on UserAuthenticationWithPasswordFailure {
          message
        }
        __typename
      }
    }
  `,
	{
		fetchPolicy: "no-cache",
	}
);

// Send a magic link to the user
const { mutate: sendMagicLinkRequest } = useMutation(
	gql`
    mutation requestToken($email: String!) {
      sendUserMagicAuthLink(email: $email)
    }
  `,
	{
		fetchPolicy: "no-cache",
	}
);

// Send a magic link to the user
const { mutate: sendSignOutRequest } = useMutation(
	gql`
    mutation {
      signout
    }
  `,
	{
		fetchPolicy: "no-cache",
	}
);

// Update preferred language
const { mutate: sendLanguageChangeRequest } = useMutation(
	gql`
    mutation UpdateMyProfileLanguage($language: String) {
      updateMyProfileLanguage(language: $language) {
        status
      }
    }
  `,
	{
		fetchPolicy: "no-cache",
	}
);

export const currentTime = useNow();

export const useAuthStore = defineStore("auth", {
	state: () => ({
		authLogin: useStorage("authLogin", {}),
		loginAccount: useStorage("authAccount", {}),
		selectedEmployee: useStorage("authSelectedEmployee", {}), // {} = no employee selected
		expiration: useStorage("authExpiration", 0),
		language: useStorage("language", ""),
	}),
	getters: {
		expired: (state) => {
			return state.expiration < (currentTime.value?.getTime() || -1);
		},
		account: (state) => state.loginAccount,
		isLoggedIn: (state) => (state.account.id ? true : false),
		employees: (state) => state.account.employee || [],
		selectedCompany: (state) => state.selectedEmployee.company?.id || null,
		profileOnly: (state) => {
			if (state.hasFinanceAccess || state.hasTaxationAccess || state.hasHrAccess) {
				return false;
			}
			return true;
		},
		hasFinanceAccess: (state) => {
			if (state.selectedEmployee.role?.canAccessFinance || state.account.role?.canManageAccounting) {
				return true;
			}
			return false;
		},
		hasTaxationAccess: (state) => {
			if (state.selectedEmployee.role?.canAccessTaxation || state.account.role?.canManageTaxation) {
				return true;
			}
			return false;
		},
		hasHrAccess: (state) => {
			if (state.selectedEmployee.role?.canManageHumanResources || state.account.role?.canManageHumanResources) {
				return true;
			}
			return false;
		},
		hasCompanyCodeAccess: (state) => {
			if (state.selectedEmployee.role?.canAccessCompanyCodes) {
				return true;
			}
			return false;
		},
		hasCompanyContactsAccess: (state) => {
			if (state.selectedEmployee.role?.canAccessCompanyContacts || state.account.role?.canManageAccounts) {
				return true;
			}
			return false;
		},
		hasHrSalaryAccess: (state) => {
			if (state.account.role?.canManageHumanResources) {
				return true;
			}
			return false;
		},
		hasProfile: (state) => {
			return state.employees.length > 0 ? true : false;
		},
	},
	actions: {
		setNewSelectedEmployee(employee) {
			this.selectedEmployee = employee;
		},
		setAccount(account) {
			this.loginAccount = account;
			if (this.employees.length > 0 && (!this.selectedEmployee || !this.selectedEmployee.id)) {
				this.setNewSelectedEmployee(this.employees[0]);
			}
		},
		setAuthLogin(auth) {
			this.expiration = auth.expires_at * 1000;
			this.authLogin = auth;
		},
		async signin(email, token) {
			//console.log("signin", email, token);
			try {
				const result = await sendRedeemTokenRequest({ email, token });
				//console.dir(result);
				//await this.tryCheckAuth();
				if (result.data.redeemUserMagicAuthToken.code) {
					//console.dir(result);
					return {
						code: result.data.redeemUserMagicAuthToken.code,
						message: result.data.redeemUserMagicAuthToken.message,
					};
				} else {
					return { code: "SUCCESS", message: "Sign-in successfull" };
				}
			} catch (e) {
				//console.dir(e);
				return { code: "ERROR", message: e.message + " (" + e.code + ")" };
			}
		},
		async magiclink(email) {
			try {
				const result = await sendMagicLinkRequest({ email });
				//console.dir(result);
				return { code: "SUCCESS", message: "Email sent" };
			} catch (e) {
				//console.dir(e);
				return { code: "ERROR", message: e.message + " (" + e.code + ")" };
			}
		},
		async updateLanguage(language) {
			try {
				const result = await sendLanguageChangeRequest({ language });
				//console.log("updateLanguage result");
				//console.dir(result);
				if (result.data.updateMyProfileLanguage.status === language) {
					return { code: "SUCCESS", message: "Language changed" };
				}
				return { code: "ERROR", message: "Could not update language" };
			} catch (e) {
				//console.log("updateLanguage error");
				//console.dir(e);
				return { code: "ERROR", message: `${e.message} (${e.code})` };
			}
		},
		async getLanguage() {
			if (this.language !== "") {
				return { code: "SUCCESS", message: "Language already set" };
			}
			try {
				// use apollo client directly
				const q = gql`
          query {
            myLanguage {
              language
            }
          }
        `;
				//console.dir(q);
				const result = await apolloClient.query(
					{
						query: q,
					},
					{ fetchPolicy: "no-cache" }
				);
				if (result.data.myLanguage) {
					this.language = result.data.myLanguage.language;
				}
				//console.log("getLanguage");
				//console.dir(result);
			} catch (e) {
				//console.dir(e);
				return { code: "ERROR", message: `${e.message} (${e.code})` };
			}
		},
		async checkauth_New() {
			if (this.expired) {
				return { code: "ERROR", message: "You are not signed-in" };
			}
			return { code: "SUCCESS", message: "You are now signed-in" };
		},
		async checkauth() {
			//console.log("auth store: checkauth");
			try {
				// use apollo client directly
				const q = gql`
          query {
            me {
              ... on User {
                ...UserFields
              }
            }
          }
          ${accountFragment}
        `;
				//console.dir(q);
				const result = await apolloClient.query(
					{
						query: q,
					},
					{ fetchPolicy: "no-cache" }
				);
				//console.dir(result);
				//console.log("auth store: checkauth");
				//console.dir(result);
				if (result.data && result.data.me && result.data.me.id) {
					this.setAccount(result.data.me);
					//this.account = result.data.authenticatedItem;
					//this.autoSelectEmployee();
					return { code: "SUCCESS", message: "You are signed-in" };
				} else {
					//console.dir(result);
					this.resetData();
					return { code: "ERROR", message: "You are not signed-in" };
				}
			} catch (e) {
				//console.dir(e);
				this.resetData();
				return { code: "ERROR", message: e.message + " (" + e.code + ")" };
			}
		},
		async signout() {
			//console.log("signout begin");
			try {
				const result = await sendSignOutRequest();

				// Reset all stores
				this.resetData();
				useCompanyStore().resetData();
				useEmployeeStore().resetData();
				useHrProfileStore().resetData();
				useHrSalaryStore().resetData();
				useFinanceStore().resetData();
				useHrDocumentStore().resetData();
				useHrSalaryControlStore().resetData();

				return { code: "SUCCESS", message: "You are now signed-out" };
			} catch (e) {
				//console.log("signout error");
				//console.dir(e);
				return { code: "ERROR", message: e.message + " (" + e.code + ")" };
			}
		},
		resetData() {
			this.authLogin = {};
			this.loginAccount = {};
			this.selectedEmployee = {};
			this.expiration = 0;
			this.language = "";
		},
	},
});
