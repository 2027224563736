import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

import { useCompanyStore } from "./company";
import { useAuthStore } from "./auth";

// GraphQL
import { provideApolloClient, useMutation } from "@vue/apollo-composable";
import apolloClient from "../graphql/client";
import gql from "graphql-tag";

provideApolloClient(apolloClient);

const { mutate: createCompanyProfileRequest } = useMutation(
  gql`
    mutation CreateCompanyEmployee($data: CompanyEmployeeCreateInput!) {
      createCompanyEmployee(data: $data) {
        id
        name
      }
    }
  `,
);

export const useEmployeeStore = defineStore("employees", {
  state: () => ({
    allEmployees: useStorage("employeesAllEmployees", []),
    selected: useStorage("employeesSelected", {}),
    lastSelected: {},
    loading: false,
    lastChange: useStorage("employeesLastChange", {}),
    filterByList: useStorage("employeesFilterByList", "normal"), // normal (with code archived=false) or archived (archived=true) or new (archived=false, no code)
    filterByName: useStorage("employeesFilterByName", ""),
  }),
  getters: {
    employees: (state) => {
      return state.allEmployees.filter((e) => e.company.id === useCompanyStore().selectedCompanyId) || [];
    },
    companyEmployees: (state) => {
      return state.allEmployees.filter((e) => e.company.id === useCompanyStore().selectedCompanyId) || [];
    },
    normalListEmployees: (state) => {
      return state.companyEmployees.filter((e) => e.isArchived === false && e.code !== "");
    },
    archivedListEmployees: (state) => {
      return state.companyEmployees.filter((e) => e.isArchived === true);
    },
    newListEmployees: (state) => {
      return state.companyEmployees.filter((e) => e.code === "" && e.isArchived === false);
    },
    filteredEmployees: (state) => {
      // Filter by list
      let allCompanyEmployeesByList;
      if (state.filterByList === "archived") {
        allCompanyEmployeesByList = state.archivedListEmployees;
      }
      if (state.filterByList === "new") {
        allCompanyEmployeesByList = state.newListEmployees
      }
      if (state.filterByList === "normal") {
        allCompanyEmployeesByList = state.normalListEmployees
      }

      // Filter by name
      let allCompanyEmployeesByListAndName;
      if (state.filterByName !== "") {
        allCompanyEmployeesByListAndName = allCompanyEmployeesByList.filter((e) => e.name.toLowerCase().includes(state.filterByName.toLowerCase()));
      } else {
        allCompanyEmployeesByListAndName = allCompanyEmployeesByList;
      }
      return allCompanyEmployeesByListAndName;
    },
    /* Used by UI */
    employeesByLetter: (state) => {
      return (letter) => state.filteredEmployees.filter((e) => e.letter === letter);
    },
    /* Used by UI */
    employeesLetters() {
      if (this.filteredEmployees.length > 0) {
        return [...new Set(this.filteredEmployees.map((employee) => employee.letter).sort())];
      }
      return [];
    },
    /*
    isSelectedAvailable(state) {
      if (this.employees.filter((employee) => employee.id === state.selected.id).length > 0) {
        return true;
      }
      return false;
    },
    */
    selectedEmployee: (state) => {
      if (useAuthStore().account.role?.canAccessAllCompanies) {
        return state.lastSelected;
      }
      if (state.allEmployees.length === 1) {
        return state.allEmployees[0];
      }
      if (state.allEmployees.length > 1) {
        return state.selected[useAuthStore().selectedCompany] ?? undefined;
      }
      return {};
    },

    /**
     * Pass in employee code and company code and return employee id
     * @param {code, companycode} state
     * @returns id of employee
     */
    employeeUsingCodes: (state) => (code, companycode) => {
      const employee = state.allEmployees.find((e) => e.code === code && e.company.code === companycode);
      return employee ? employee : null;
    },
    allEmployeesReturnEmployeeUsingId: (state) => (userid) => {
      let employee = null;

      const find = state.allEmployees.find((e) => e.id === userid);
      if (find) {
        employee = find;
      }

      return employee;
    },
  },
  actions: {
    async getData() {
      try {
        if (this.loading) return;
        this.loading = true;

        //const searchStore = useSearchStore();
        //console.dir(searchStore.getHrCompanyFilter);

        const variablesWhere = {};

        // use apollo client directly
        const result = await apolloClient.query({
          query: gql`
            query Query($orderBy: [CompanyEmployeeOrderByInput!]!, $where: CompanyEmployeeWhereInput!) {
              companyEmployees(orderBy: $orderBy, where: $where) {
                id
                code
                name
                jobDescription
                hrDocumentNotification
                hrUnReadChatMessages
                letter
                settingHrDocumentsDisabled
                user {
                  emailHash
                }
                company {
                  id
                  code
                  name
                }
                isArchived
              }
            }
          `,
          variables: {
            orderBy: {
              name: "asc",
            },
            where: variablesWhere,
          },
          fetchPolicy: "no-cache",
        });
        //console.dir(result);
        if (result.data?.companyEmployees) {
          if (result.data.companyEmployees.length > 0) {
            this.allEmployees = result.data.companyEmployees;
            this.autoSelectFirstEmployeeUsingLetters();
            this.loading = false;
            return { code: "SUCCESS", message: "Load complete" };
          }
          this.documents = [];
          this.loading = false;
          return { code: "SUCCESS", message: "No employees found" };

        }
        //console.dir(result);
        this.loading = false;
        return { code: "ERROR", message: "Error while loading employees" };

      } catch (e) {
        //console.dir(e);
        this.loading = false;
        return { code: "ERROR", message: `${e.message} (${e.code})` };
      }
    },
    async createCompanyEmployee(name) {
      try {
        const result = await createCompanyProfileRequest({
          data: {
            name, company: { connect: { id: useCompanyStore().selectedCompanyId } }
          },
        });
        if (result.errors) {
          return { code: "ERROR", message: result.errors[0].message };
        }
        if (result.data.createCompanyEmployee.error) {
          return {
            code: "ERROR",
            message: result.data.createCompanyEmployee.message,
          };
        }
        return result;
      } catch (e) {
        return { code: "ERROR", message: `${e.message} (${e.code})` };
      }
    },
    resetData() {
      this.allEmployees = [];
      this.selected = {};
      this.lastSelected = {};
      this.loading = false;
      this.lastChange = {};
      this.filterByList = "normal";
      this.filterByName = "";
    },
    setEmployee(employee) {
      if (!employee) return;

      if (employee.id && this.lastSelected.id !== employee.id) {
        this.lastSelected = employee;
        this.selected[employee.company.id] = employee;
      }
    },
    autoSelectFirstEmployeeUsingLetters() {
      const letter = this.employeesLetters[0];
      const eByLetter = this.employeesByLetter(letter);

      if (eByLetter.length > 0 && eByLetter[0]) {
        this.setEmployee(eByLetter[0]);
      }
    },
  },
});
